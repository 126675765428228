import { RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ImageAssetAlternativeType } from '@remento/types/alternative';
import { BaseEntityStatus } from '@remento/types/base-entity';
import { ProjectStatus, PromptStatus, PromptType } from '@remento/types/project';

import TextPromptThumbnailSrc from '@/assets/prompt-thumbnail.svg';
import { RMConfirmationModal } from '@/components/RMConfirmationModal';
import { toast } from '@/components/RMToast/RMToast';
import { RecordingStorytellerSelectionDialogContainer } from '@/modules/recording/containers/RecordingStorytellerSelectionDialog.container';
import { getRecordingIntroPath } from '@/modules/routing';
import { useServices } from '@/Services';
import { useUser } from '@/services/api/auth/auth.service.hook';
import { usePersonQuery } from '@/services/api/person';
import {
  useProjectQuery,
  usePromptFirstQuestionQuery,
  usePromptImageUrl,
  usePromptQuery,
  useUnrecordedProjectPrompts,
} from '@/services/api/project';
import { getProjectCron } from '@/services/api/project/project.utils';
import { captureException } from '@/utils/captureException';

import { PromptList, PromptListItemAction } from '../components/PromptList';
import { PromptListRootRef } from '../components/PromptList/PromptListRoot';
import { openUpdatePromptDialog, ProjectDialogPanelStore } from '../states/project-dialog-panel.state';

export type PromptListItemContainerProps = {
  promptId: string;
  projectId: string;
  sendOn: number;
  projectStatus: ProjectStatus | BaseEntityStatus;
  onClick: (id: string) => void;
  onAction: (id: string, action: PromptListItemAction) => void;
  onMove: (id: string, direction: 'up' | 'down') => void;
};

export function PromptListItemContainer({
  promptId,
  projectId,
  sendOn,
  projectStatus,
  onClick,
  onAction,
  onMove,
}: PromptListItemContainerProps) {
  const promptQuery = usePromptQuery(promptId);
  const questionQuery = usePromptFirstQuestionQuery(promptId);
  const imageUrl = usePromptImageUrl(promptQuery.data ?? null, ImageAssetAlternativeType.SMALL);

  const user = useUser();
  const projectQuery = useProjectQuery(projectId);
  const userIsRecipient = projectQuery.data?.notifications.recipientPersonIds.includes(user?.personId ?? '');
  const authorPersonQuery = usePersonQuery(promptQuery.data?.requesterPersonId);

  if (!promptQuery.data || !questionQuery.data) {
    // TODO-BOOK: Loading/error state
    return null;
  }

  return (
    <PromptList.Item
      id={promptQuery.data.id}
      image={promptQuery.data.type === PromptType.TEXT ? TextPromptThumbnailSrc : imageUrl}
      title={questionQuery.data.text}
      timestamp={promptQuery.data.sentOn || sendOn}
      projectStatus={projectStatus}
      status={promptQuery.data.status}
      userIsRecipient={userIsRecipient}
      timePeriod={projectQuery.data?.configuration.timePeriod}
      author={authorPersonQuery.data?.name?.full}
      onClick={onClick}
      onAction={onAction}
      onMove={onMove}
    />
  );
}

export interface PromptListContainerProps {
  projectId: string;
  dialogPanelStore: ProjectDialogPanelStore;
  status: PromptStatus;
  listRef?: RefObject<PromptListRootRef>;
}

export function PromptListContainer({ projectId, dialogPanelStore, status, listRef }: PromptListContainerProps) {
  const { projectService, projectCacheService, entityCacheManagerService, promptAnalyticsService } = useServices();

  const projectQuery = useProjectQuery(projectId);
  const project = projectQuery.data;
  const promptsIdsQuery = useUnrecordedProjectPrompts(projectId, status);
  const storytellerQuery = usePersonQuery(project?.subjectPersonIds?.[0]);
  const user = useUser();

  const [recordingStorytellerSelectionDialogPrompt, setRecordingStorytellerSelectionDialogPrompt] = useState<
    string | null
  >(null);

  const lastSentPromptIndex = useRef<number>();

  const selectedPromptId = useRef<string | null>(null);

  const handleMoveToIndex = useCallback(
    async (promptId: string, newIndex: number) => {
      // Cannot move prompt to a position before the last one sent
      if (lastSentPromptIndex.current != null && newIndex <= lastSentPromptIndex.current) {
        return;
      }

      const cachedPrompt = await projectCacheService.getPrompt(promptId);
      if (!cachedPrompt) {
        throw new Error('Missing cached prompt with ID ' + promptId);
      }

      // Cannot move prompt already sent
      if (cachedPrompt.status === PromptStatus.SENT) {
        return;
      }

      const mutations = projectService.createMovePromptMutation(cachedPrompt, newIndex);
      await entityCacheManagerService.mutate(mutations);

      promptAnalyticsService.onUpcomingPromptsReordered();
    },
    [entityCacheManagerService, promptAnalyticsService, projectCacheService, projectService],
  );

  const handleMoveToId = useCallback(
    async (draggedPromptId: string, droppedPromptId: string) => {
      const droppedPrompt = await projectCacheService.getPrompt(droppedPromptId);
      if (!droppedPrompt) {
        return;
      }
      await handleMoveToIndex(draggedPromptId, droppedPrompt.index);
    },
    [handleMoveToIndex, projectCacheService],
  );

  const handleMoveToDirection = useCallback(
    async (draggedPromptId: string, direction: 'up' | 'down') => {
      if (!promptsIdsQuery) {
        return;
      }

      const index = promptsIdsQuery.indexOf(draggedPromptId);
      if (index === -1) {
        return;
      }

      const droppedPromptId = promptsIdsQuery[direction === 'up' ? index - 1 : index + 1];
      if (!droppedPromptId) {
        return;
      }

      const droppedPrompt = await projectCacheService.getPrompt(droppedPromptId);
      if (!droppedPrompt) {
        return;
      }

      await handleMoveToIndex(draggedPromptId, droppedPrompt.index);
    },
    [promptsIdsQuery, handleMoveToIndex, projectCacheService],
  );

  // Send prompt now
  const [sendPromptNowModalOpen, setSendPromptNowModalOpen] = useState(false);

  const handleSendPromptNow = useCallback(async () => {
    try {
      if (selectedPromptId.current === null) {
        return;
      }

      const cachedPrompt = await projectCacheService.getPrompt(selectedPromptId.current);
      if (!cachedPrompt) {
        throw new Error('Missing cached prompt with ID ' + selectedPromptId.current);
      }

      const mutations = projectService.createSetSentMutation(cachedPrompt);
      await entityCacheManagerService.mutate(mutations);

      setSendPromptNowModalOpen(false);
      selectedPromptId.current = null;
      toast('Your prompt will be sent shortly');
    } catch (error) {
      captureException(error, true);
      toast('An unexpected error has occurred.', 'root-toast', 'error');
    }
  }, [entityCacheManagerService, projectCacheService, projectService]);

  const handleCancelSendPromptNow = useCallback(() => {
    setSendPromptNowModalOpen(false);
    selectedPromptId.current = null;
  }, []);

  const goToRecord = useCallback(
    (promptId: string, recorderPersonId?: string) => {
      const searchParams = new URLSearchParams();
      if (recorderPersonId != null) {
        searchParams.set('recorder-person-id', recorderPersonId);
      }

      window.open(getRecordingIntroPath(projectId, promptId, searchParams), '_blank');
      promptAnalyticsService.onUpcomingPromptsActionPerformed('record');
    },
    [projectId, promptAnalyticsService],
  );

  const handleRecordingStorytellerSelectionDialogConfirm = useCallback(
    (recorderPersonId: string) => {
      if (recordingStorytellerSelectionDialogPrompt == null) {
        return;
      }

      goToRecord(recordingStorytellerSelectionDialogPrompt, recorderPersonId);
      setRecordingStorytellerSelectionDialogPrompt(null);
    },
    [goToRecord, recordingStorytellerSelectionDialogPrompt],
  );

  const handleCloseRecordingStorytellerSelectionDialog = useCallback(() => {
    setRecordingStorytellerSelectionDialogPrompt(null);
  }, []);

  const handleAction = useCallback(
    async (promptId: string, action: PromptListItemAction) => {
      const cachedPrompt = await projectCacheService.getPrompt(promptId);
      if (!cachedPrompt) {
        throw new Error('Missing cached prompt with ID ' + promptId);
      }

      switch (action) {
        case 'RECORD': {
          const userIsStoryteller =
            storytellerQuery.data == null ||
            user?.personId == null ||
            storytellerQuery.data.refIds.includes(user.personId);

          if (userIsStoryteller) {
            goToRecord(promptId);
          } else {
            setRecordingStorytellerSelectionDialogPrompt(promptId);
          }

          break;
        }
        case 'EDIT': {
          openUpdatePromptDialog(dialogPanelStore, promptId);
          promptAnalyticsService.onUpcomingPromptsActionPerformed('edit');
          break;
        }
        case 'SEND-NOW': {
          selectedPromptId.current = promptId;
          setSendPromptNowModalOpen(true);
          promptAnalyticsService.onUpcomingPromptsActionPerformed('send-now');
          break;
        }
      }
    },
    [dialogPanelStore, promptAnalyticsService, projectCacheService, storytellerQuery, user, goToRecord],
  );

  useEffect(() => {
    const checkLastSentPromptIndex = async () => {
      const promptIds = promptsIdsQuery;
      if (!promptIds) {
        return;
      }

      const prompts = await Promise.all(promptIds.map((id) => projectCacheService.getPrompt(id)));
      // findLastIndex is not supported in some safari versions
      lastSentPromptIndex.current = -1;
      for (let index = prompts.length - 1; index >= 0; index -= 1) {
        const prompt = prompts[index];
        if (prompt !== null && prompt.status === PromptStatus.SENT) {
          lastSentPromptIndex.current = index;
          break;
        }
      }
    };

    checkLastSentPromptIndex();
  }, [promptsIdsQuery, projectCacheService]);

  const ListItems = useMemo(() => {
    if (project == null) return [];

    const cron = getProjectCron(project);

    return promptsIdsQuery?.map((promptId) => {
      const sendOn = cron.next().toDate().getTime();

      return (
        <PromptListItemContainer
          key={promptId}
          projectId={projectId}
          promptId={promptId}
          sendOn={sendOn}
          projectStatus={project.status}
          onClick={(id) => handleAction(id, 'EDIT')}
          onAction={handleAction}
          onMove={handleMoveToDirection}
        />
      );
    });
  }, [project, promptsIdsQuery, projectId, handleAction, handleMoveToDirection]);

  if (projectQuery.isLoading) {
    // TODO-BOOK: Loading state
    return null;
  }

  if (projectQuery.isError) {
    // TODO-BOOK: Error state
    return null;
  }

  if (promptsIdsQuery == null || promptsIdsQuery.length === 0) {
    return null;
  }

  return (
    <>
      <PromptList.Root ref={listRef} items={promptsIdsQuery ?? []} onMove={handleMoveToId}>
        {ListItems}
      </PromptList.Root>

      <RecordingStorytellerSelectionDialogContainer
        projectId={projectId}
        open={recordingStorytellerSelectionDialogPrompt != null}
        onClose={handleCloseRecordingStorytellerSelectionDialog}
        onContinue={handleRecordingStorytellerSelectionDialogConfirm}
      />

      <RMConfirmationModal
        open={sendPromptNowModalOpen}
        title="Send prompt now?"
        message={`Proceeding will immediately send ${storytellerQuery.data?.name?.first} this prompt.`}
        cancelLabel="Nevermind"
        confirmLabel="Send now"
        onConfirm={handleSendPromptNow}
        onCancel={handleCancelSendPromptNow}
        onClose={handleCancelSendPromptNow}
      />
    </>
  );
}
